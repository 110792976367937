'use strict'

export default (element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): void => {
  const containerEl = element.closest('.o-form__block')

  if (!containerEl) {
    return
  }

  const focusBlockFn = function (this: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): void {
    containerEl.classList.add('o-form__field--focused')
  }

  const filledBlockFn = function (this: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): void {
    containerEl.classList.remove('o-form__field--focused')

    setTimeout(() => {
      if (this.value === '') {
        containerEl.classList.remove('o-form__field--filled')
      } else {
        containerEl.classList.add('o-form__field--filled')
      }
    }, 0)
  }

  const checkFieldValidityFn = function (this: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): void {
    if (!element.checkValidity) {
      return
    }

    const validity = element.checkValidity()

    if (containerEl && validity) {
      containerEl.classList.remove('o-form__field--invalid')
      containerEl.classList.add('o-form__field--valid')
    } else if (containerEl && !validity) {
      containerEl.classList.remove('o-form__field--valid')
      containerEl.classList.add('o-form__field--invalid')
    }
  }

  element.addEventListener('click', focusBlockFn)

  element.addEventListener('focus', focusBlockFn)

  element.addEventListener('blur', filledBlockFn)
  element.addEventListener('blur', checkFieldValidityFn)

  element.addEventListener('change', filledBlockFn)
  element.addEventListener('change', checkFieldValidityFn)
}
